export const palette = {
  google: {
    main: '#DB4437',
    contrastText: '#FFFFFF',
  },
  linkedin: {
    main: '#0A66C2',
    contrastText: '#FFFFFF',
  },
  gray: {
    main: '#eff4f5',
    contrastText: '#707274',
  },
};
