const routes = require('../names');

const de = {
  [routes.acceptInvite]: {
    pathname: '/einladung-akzeptieren',
  },
  [routes.accountActivate]: {
    pathname: '/profil-aktivierung',
  },
  [routes.accountActivateProviderLink]: {
    pathname: '/profil-aktivierung-provider-link',
  },
  [routes.forgotPassword]: {
    pathname: '/passwort-vergessen',
  },
  [routes.login]: {
    pathname: '/anmeldung',
  },
  [routes.messageCenter]: {
    pathname: '/nachrichten',
  },
  [routes.profile]: {
    pathname: '/profil/[userId]/[[...action]]',
  },
  [routes.register]: {
    pathname: '/registrieren',
  },
  [routes.restorePassword]: {
    pathname: '/passwort-wiederherstellen',
  },
  [routes.settings]: {
    pathname: '/einstellungen',
  },
  [routes.users]: {
    pathname: '/nutzer',
  },
  [routes.userInvite]: {
    pathname: '/management/benutzer-einladen',
  },
  [routes.usersActive]: {
    pathname: '/nutzer/aktiv',
  },
  [routes.userEdit]: {
    pathname: '/nutzer/bearbeiten/[id]',
  },
  [routes.files]: {
    pathname: '/dateien',
  },
  [routes.filesEdit]: {
    pathname: '/dateien/bearbeiten/[id]',
  },
  [routes.typography]: {
    pathname: '/typographiee',
  },
  [routes.home]: {
    pathname: '/',
  },
  [routes.imprint]: {
    pathname: '/impressum',
  },
  [routes.terms]: {
    pathname: '/geschaeftsbedingungen',
  },
  [routes.privacy]: {
    pathname: '/datenschutz',
  },
  [routes.dataPreferences]: {
    pathname: '/data-preferences',
  },
  [routes.management]: {
    pathname: '/management',
  },
  [routes.adminstrationUsers]: {
    pathname: '/verwaltung/benutzer',
  },
  [routes.adminstrationCustomers]: {
    pathname: '/verwaltung/konto',
  },
  [routes.companies]: {
    pathname: '/management/firmen',
  },
  [routes.customersUsers]: {
    pathname: '/management/kunden',
  },
  [routes.invitations]: {
    pathname: '/management/einladungen',
  },
  [routes.helmUsers]: {
    pathname: '/management/helm-nutzer',
  },
  [routes.userInvitesEdit]: {
    pathname: '/management/einladen/[inviteId]',
  },
  [routes.contracts]: {
    pathname: '/verträge',
  },
  [routes.contractsOld]: {
    pathname: '/verträge-alt',
  },
  [routes.contractDetails]: {
    pathname: '/verträge/[id]/[type]',
  },
  [routes.orderDetails]: {
    pathname: '/aufträge/[id]',
  },
  [routes.orderDeliveries]: {
    pathname: '/orders/[id]/lieferungen',
  },
  [routes.dashboard]: {
    pathname: '/armaturenbrett',
  },
  [routes.orders]: {
    pathname: '/aufträge',
  },
  [routes.orderDeliveryDetails]: {
    pathname: '/aufträge/[id]/lieferungen/[deliveryId]',
  },
  [routes.deliveryDetails]: {
    pathname: '/Lieferungen/[deliveryId]',
  },
  [routes.marketIntelligence]: {
    pathname: '/markt-intelligenz',
  },
  [routes.marketIntelligenceCreate]: {
    pathname: '/markt-intelligenz/schaffen',
  },
  [routes.marketIntelligenceEdit]: {
    pathname: '/markt-intelligenz/bearbeiten/[id]',
  },
  [routes.deliveries]: {
    pathname: 'lieferungen',
  },
  [routes.companyCustomEmails]: {
    pathname: '/management/firmen/[id]/brauch-emails',
  },
};

module.exports = de;
