export const helmBlue = {
  '50': '#e8ecf2',
  '100': '#d1d8e4',
  '200': '#bac5d7',
  '300': '#a3b2c9',
  '400': '#8b9fbc',
  '500': '#748baf',
  '600': '#5d78a1',
  '700': '#466594',
  '800': '#2f5186',
  '900': '#183e79',
};
