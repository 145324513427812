import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  FetchContractsInterface,
  fetchContracts,
} from 'modules/contract-release/hooks/use-fetch-contract-release.hook';
import { FetchOrdersResponseInterface } from 'modules/orders/hooks';
import { ContractFilter } from 'views/contract-release/hooks';
import { StoreInterface } from 'configuration/redux/store';
import { fetchOrders } from 'modules/orders/hooks/use-fetch-orders.hook';
import { OrderTypeEnum } from 'views/orders/enums';
import { OrderEnum } from 'modules/common/enums';
import { ContractReleaseSortEnum } from 'views/contract-release/enums';

export interface OrderTypeListStateInterface {
  data: { [key: string]: { contracts?: FetchContractsInterface; orders?: FetchOrdersResponseInterface } };
  isLoading: boolean;
  error: string | null;
  totalCount?: number;
}

const initialState: OrderTypeListStateInterface = {
  data: {},
  isLoading: false,
  error: null,
  totalCount: 0,
};

export const fetchOrderTypeListThunk = createAsyncThunk(
  'contractsForOrderTypeList/fetch',
  async (filter: ContractFilter, { getState }) => {
    const { orderTypeList } = getState() as StoreInterface;
    // Serialize filter for caching key
    const filterKey = JSON.stringify(filter);
    if (orderTypeList.data[filterKey]) {
      return { filterKey, data: orderTypeList.data[filterKey] };
    } else {
      const result = await Promise.allSettled([
        fetchContracts({ filter, order: { order: OrderEnum.DESC, sort: ContractReleaseSortEnum.HELM_CONTRACT_ID }, limit: 50 }),
        fetchOrders({
          filter: {
            kunnr: filter.kunnrs[0],
            productIds: filter.productIds,
            orderTypes: [OrderTypeEnum.SPOT],
          },
        }),
      ]);

      const contracts = result[0]?.status === 'fulfilled' ? result[0].value : { data: [], totalCount: 0 };
      const orders = result[1]?.status === 'fulfilled' ? result[1].value : { data: [], totalCount: 0 };

      return { filterKey, data: { contracts, orders } };
    }
  },
);

const orderTypeListSlice = createSlice({
  name: 'orderTypeListSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderTypeListThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrderTypeListThunk.fulfilled, (state, action) => {
        const { filterKey, data } = action.payload;
        state.data[filterKey] = data; // Cache fetched data
        state.isLoading = false;
      })
      .addCase(fetchOrderTypeListThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default orderTypeListSlice.reducer;
