export const helmTeal = {
    '50': '#d2e4ed',
    '100': '#bcd9e5',
    '200': '#a6cedd',
    '300': '#90c3d5',
    '400': '#1f7e87',
    '500': '#68C0B5',
    '600': '#4ea2bc',
    '700': '#3897b4',
    '800': '#228cae',
    '900': '#006173'
  }
  