// color palettes generated by https://material.io/inline-tools/color/
export const darkJungleGreen = {
  '50': '#f1f7ff',
  '100': '#e9f0ff',
  '200': '#dfe5ff',
  '300': '#ced4f0',
  '400': '#aab0cb',
  '500': '#8a90a9',
  '600': '#626880',
  '700': '#4f556c',
  '800': '#30374c',
  '900': '#0f172a',
};
