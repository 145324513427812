import { publicConfig } from 'configuration/app';

export const setCookie = (name: string, value: string, maxAgeSeconds?: number): void => {
  const maxAgeStr = isNaN(maxAgeSeconds) ? `Max-Age=${publicConfig.cookie.maxAge}` : `Max-Age=${maxAgeSeconds}`;
  document.cookie = `${name} = ${value}; ${maxAgeStr}`;
};

export const clearCookie = (name: string) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const getCookie = (name: string) => {
  let cookies = document.cookie;
  let cookieArray = cookies.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookiePair = cookieArray[i].split('=');

    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
};

export const getCookieJson = (name: string) => {
  const cookieString = getCookie(name);

  if (cookieString) {
    try {
      return JSON.parse(cookieString);
    } catch (e) {}
  }

  return null;
};
