import { PaletteMode } from '@mui/material';
import { blueGrey, helmTeal } from 'configuration/theme/colors';


export const lightThemeConfig = {
  palette: {
    mode: 'light' as PaletteMode,
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    primary: {
      main: '#006173',
      light: '#CBD5E1',
      contrastText: '#ccdfe3',
      '500': '#6200EE',
    },
    secondary: {
      main: '#68C0B5',
      light: '#68C0B5',
      contrastText: '#FFFFFF',
    },
    gray: {
      main: '#eff4f5',
      contrastText: '#707274',
    },
    error: {
      main: '#F75959',
      light: '#FCA5A5',
      dark: '#DC2626',
      contrastText: 'rgba(15, 23, 42, 0.87)',
    },
    warning: {
      main: '#F59E0B',
      light: '#FCD34D',
      dark: '#D97706',
      contrastText: 'rgba(15, 23, 42, 0.87)',
    },
    info: {
      main: '#3B82F6',
      light: '#93C5FD',
      dark: '#2563EB',
      contrastText: 'rgba(15, 23, 42, 0.87)',
    },
    success: {
      main: '#11AE59',
      light: '#6EE7B7',
      dark: '#059669',
      contrastText: 'rgba(15, 23, 42, 0.87)',
    },
    grey: blueGrey,
    text: {
      primary: '#0A0B0F',
      secondary: '#626262',
      disabled: 'rgba(15, 23, 42, 0.26)',
    },
    divider: '#0061731A',
    background: {
      paper: '#FFFFFF',
      default: blueGrey[50],
    },
    action: {
      active: 'rgba(15, 23, 42, 0.54)',
      hover: 'rgba(15, 23, 42, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(15, 23, 42, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(15, 23, 42, 0.26)',
      disabledBackground: 'rgba(15, 23, 42, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(15, 23, 42, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
      link: 'rgba(66, 148, 247, 1)',
    },
  },
  components: {
    '.MuiPaper-root-MuiDrawer-paper': {
      styleOverrides: {
        borderRadius: "0 !important",
        border: 'none !important'
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 800,
          gap: '0.5rem',
          borderRadius: '2px !important',
          textTransform: 'none',
          letterSpacing: 1,
        },
        contained: {
          boxShadow: 'none',
          color: 'white',
          fontFamily: 'Graphik-SemiBold !important',
          fontSize: '15px !important',
          padding: '10px 18px !important',
          lineHeight: '120%',
          letterSpacing: '0.46px',
        },
        outlinedPrimary: {
          borderColor: helmTeal[200],
          color: helmTeal[900],
          maxHeight: '18px !important',
          fontFamily: 'Graphik-SemiBold !important',
          fontSize: '15px !important',
          padding: '10px 18px !important',
          letterSpacing: '0.46px',
          lineHeight: '120%',
        },
        containedGray: {
          backgroundColor: '#eff4f5',
          border: 'solid 1px #eff4f5',
          boxShadow: 'none',
        },
        textGray: {
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          color: '#707274',
        },
        sizeSmall: {
          minWidth: '59px !important',
          height: '26px !important',
          maxHeight: '26px !important',
          fontSize: '13px',
          padding: '5px 11px 5px 11px',
        },
        composedButton: {
          boxShadow: 'none',
          color: 'white',
          alignItems: 'baseline',
          backgroundColor: '#006173 !important',
          fontFamily: 'Graphik-SemiBold !important',
          padding: '10px 18px !important',
          lineHeight: '18px',
          "& .MuiButton-startIcon": {
            marginRight: "0px",
            alignSelf: 'center'
          },
          '&.Mui-disabled': {
            backgroundColor: 'rgba(15, 23, 42, 0.26) !important',
          },
          fontSize: '14px !important',
          letterSpacing: '0.4px !important',
        },
        filterButton: {
          fontSize: '14px !important',
          fontWeight: 600,
          lineHeight: '120%',
          letterSpacing: '0.4px',
          boxShadow: 'none',
          fontFamily: 'Graphik-SemiBold !important',
          minWidth: 'auto',
          whiteSpace: 'nowrap',
          height: '20px !important',
          minHeight: '17px',
          padding: '6px 8px !important',
          gap: '8px',
          alignItems: 'stretch',
          '& svg': {
            width: '17px',
            height: '17px',
            alignSelf: 'center',
          },
        },
        stackButton: {
          boxShadow: 'none',
          color: 'white',
          fontFamily: 'Graphik-SemiBold !important',
          fontSize: '14px !important',
          padding: '8px 14px !important',
          lineHeight: '166%',
          letterSpacing: '0.17px',
          ':hover': {
            boxShadow: 'none'
          }
        },
        iconButton: {
          "& .MuiButton-startIcon": {
            marginRight: "0px",
            alignSelf: 'center'
          },
          alignItems: 'baseline',
          boxShadow: 'none',
          color: 'white',
          fontFamily: 'Graphik-SemiBold !important',
          fontSize: '14px !important',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '120%',
          letterSpacing: '0.4px',
        },
        sizeMedium: {
          minWidth: '67px',
          height: '33px !important',
          maxHeight: '33px !important',
          padding: '8px 14px 8px 14px !important',
          fontSize: '14px',
        },
        sizeLarge: {
          minWidth: '78px !important',
          height: '38px !important',
          maxHeight: '38px !important',
          padding: '10px 18px 10px 18px',
          fontSize: '16px',
        },
        primary: {
          color: '#006173',
        },
        secondary: {
          color: '#68C0B5',
        },
      },
      sizeXlarge: {
        minWidth: '78px !important',
        height: '46px !important',
        maxHeight: '46px !important',
        padding: '14px 18px !important',
        fontSize: '15px',
        fontWeight: '600',
        color: 'white',
        lineHeight: '18px',
        letterSpacing: '0.46px',
      },
      variants: [
        {
          props: { size: 'xlarge' },
          style: {
            minWidth: '78px !important',
            height: '46px !important',
            maxHeight: '46px !important',
            padding: '14px 18px !important',
            fontSize: '15px',
            fontWeight: '600',
            color: 'white',
            lineHeight: '18px',
            letterSpacing: '0.46px',
          }
      },
        {
          props: { variant: 'filterButton', color: 'primary' },
          style: {
            color: '#006173',
          }
        },
        {
          props: { variant: 'filterButton', color: 'secondary' },
          style: {
            color: '#68C0B5'
          }
        },
        {
          props: { variant: 'iconButton', color: 'primary' },
          style: {
            color: '#006173',
          }
        },
        {
          props: { variant: 'iconButton', color: 'secondary' },
          style: {
            color: '#68C0B5'
          }
        },
        {
          props: { variant: 'stackButton', color: 'secondary' },
          style: {
            backgroundColor: '#68C0B5',
            ':hover': {
              backgroundColor: '#68C0B5'
            }
          }
        },
        {
          props: { variant: 'stackButton', color: 'gray' },
          style: {
            color: '#707274',
            ':hover': {
              backgroundColor: '#eff4f5',
            },
          }
        },
      ]
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: '2px !important',
          padding: '6px 12px !important',
          backgroundColor: 'rgba(56, 88, 118, 0.06) !important',
          '&:after': {
            borderBottom: '1px solid #CBD5E1',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: 'small',
          color: '#626262',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          padding: '6px 12px !important',
          backgroundColor: 'rgba(56, 88, 118, 0.06) !important',
          borderRadius: '2px !important',
          '& > *': {
            borderRadius: '0 !important',
          },
          '& ::placeholder': {
            fontStyle: 'Italic',
            fontSize: '16px'
          },
          '&:fieldset': { border: 'none' },
          '&:hover': {
            backgroundColor: blueGrey[50],
          },
          '&.Mui-focused': {
            backgroundColor: blueGrey[50],
          },
          '&.Mui-disabled': {
            backgroundColor: blueGrey[50],
          },
        },
        input: {
          padding: '0px !important'
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '12px !important',
          fontWeight: 400,
          lineHeight: '143% !important',
          letterSpacing: '0.17px',
        },
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
          borderBottomColor: '#EEEEEE',
          padding: '16px !important',
          fontSize: '15px !important',
          fontWeight: 400,
          lineHeight: '160%',
          letterSpacing: '0.17px',
        },
        footer: {
          padding: '0 !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '2px !important',
          boxShadow: 'none !important',
          flex: 1,
          MuiDrawer: {
            styleOverrides: {
              Paper: {
                borderRadius: '0px !important',
                borderRight: '0px !important'
              }
            }
          },
        },
        elevation8: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: '2px !important',
          boxShadow: 'none !important',
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#000000',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#000000',
              color: '#FFFFFF',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(0, 97, 115, 0.8) !important',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '116.667%',
          color: 'white',
          whiteSpace: 'nowrap',
          width: 'max-content !important',
          minWidth: 'max-content'
        },
        arrow: {
          color: 'rgba(0, 97, 115, 0.8) !important',
        },
      }
    },
    MuiCard: {
      styleOverrides: {
        borderRadius: '2px !important',
        boxShadow: 'none !important'
      }
    },
    MuiContainer: {
      styleOverrides: {
        borderRadius: '2px !important',
        boxShadow: 'none !important'
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Define styles for the nested FilledInput component with size small
        root: {
          '& .MuiFilledInput-root.MuiInputBase-sizeSmall': {
            backgroundColor: 'rgba(56, 88, 118, 0.06) !important',
            borderRadius: '2px !important',
            minHeight: '38px'
          },
          '& > *': {
            borderRadius: 'none !important',
          }
        },
        endAdornment: {
          top: `calc(56% - 14px)`
        }
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0 !important'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#99C0C7',
          '&.MuiAvatar-root': {
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '& .MuiAlert-message': {
            paddingTop: 5,
          },
          '&.MuiAlert-standardSuccess': {
            backgroundColor: '#EDF7ED',
            color: '#00865E',
            '& .MuiAlertTitle-root': { color: '#00865E' },
            '& .MuiAlert-icon': { color: '#00865E' },
          },
          '&.MuiAlert-standardError': {
            backgroundColor: '#FDEDED',
            color: '#C11333',
            '& .MuiAlertTitle-root': { color: '#C11333' },
            '& .MuiAlert-icon': { color: '#C11333' },
          },
          '&.MuiAlert-standardWarning': {
            backgroundColor: '#FFF3E5',
            color: '#EF7A2A',
            '& .MuiAlertTitle-root': { color: '#EF7A2A' },
            '& .MuiAlert-icon': { color: '#EF7A2A' },
          },
          '&.MuiAlert-standardInfo': {
            backgroundColor: '#E5F5FD',
            color: '#2B93D5',
            '& .MuiAlertTitle-root': { color: '#2B93D5' },
            '& .MuiAlert-icon': { color: '#2B93D5' },
          },
        },
      },
    },
  },
  typography: {
    h1: {
      color: helmTeal[900],
      fontFamily: ['Graphik-SemiBold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    h2: {
      color: helmTeal[900],
      fontFamily: ['Graphik-SemiBold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    h3: {
      color: helmTeal[900],
      fontFamily: ['Graphik-Bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    h4: {
      color: helmTeal[900],
    },
    h5: {
      color: helmTeal[900],
    },
    h6: {
      color: helmTeal[900],
    },
    body1: {
      color: '#868c97',
      lineHeight: '32px',
    },
    body2: {
      color: 'black',
      lineHeight: '32px',
    },
    summaryText: {
      fontFamily: 'Graphik-Regular',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '166% !important',
      letterSpacing: '0.17px',
    },
    bold: {
      fontFamily: ['Graphik-Bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    boldItalic: {
      fontFamily: ['Graphik-Bold-Italic', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    semiItalic: {
      fontFamily: ['Graphik-SemiBold-Italic', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    regular: {
      fontFamily: ['Graphik-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    regularItalic: {
      fontFamily: ['Graphik-Italic', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    semiBold: {
      fontFamily: ['Graphik-SemiBold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    fontFamily: ['Graphik-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },

};

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bold: true;
    semiBold: true;
    boldItalic: true;
    semiItalic: true;
    regular: true;
    regularItalic: true;
    summaryText: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xlarge: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    textGray: true;
    sizeSmall: true;
    sizeMedium: true;
    filterButton: true;
    contained: true;
    iconButton: true;
    stackButton: true;
    composedButton: true;
  }
}