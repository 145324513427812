export const breakpoints = {
  values: {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    xxl: 1920,
    xxxl: 2560,
  },
}
