export enum UserManagementViewOrderSortEnum {
  UPDATED_AT = 'UPDATED_AT',
  INVITED_TO_COMPANY_NAME = 'INVITED_TO_COMPANY_NAME',
  USER_UPDATED_AT = 'USER_UPDATED_AT',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  INVITED_BY_USER_FIRST_NAME = 'INVITED_BY_USER_FIRST_NAME',
  STATUS = 'STATUS',
  LAST_STATUS_CHANGE = 'LAST_STATUS_CHANGE',
  LAST_LOGIN = 'LAST_LOGIN',
}
