export const publicRuntimeConfig = {
  NEXT_PUBLIC_BACKEND_HOST: process.env.NEXT_PUBLIC_BACKEND_HOST,
  NEXT_PUBLIC_BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL,
  NEXT_PUBLIC_BACKEND_API_URI: process.env.NEXT_PUBLIC_BACKEND_API_URI,
  NEXT_PUBLIC_SOCKET_BACKEND_URL: process.env.NEXT_PUBLIC_SOCKET_BACKEND_URL,
  NEXT_PUBLIC_PLATFORM_HOST: process.env.NEXT_PUBLIC_PLATFORM_HOST,
  NEXT_PUBLIC_PLATFORM_API_URI: process.env.NEXT_PUBLIC_PLATFORM_API_URI,
  NEXT_PUBLIC_LOCALE_DEFAULT: process.env.NEXT_PUBLIC_LOCALE_DEFAULT,
  NEXT_PUBLIC_PLATFORM_URL: process.env.NEXT_PUBLIC_PLATFORM_URL,
  NEXT_PUBLIC_SESSION_CACHE_TTL: process.env.NEXT_PUBLIC_SESSION_CACHE_TTL
}
