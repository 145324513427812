import { useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface UseScreenSizeHook {
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isXLarge: boolean;
  isXs: boolean;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  isBetween: (start: number, finish: number) => boolean;
  currentWidth: number;
}

export const useScreenSize = (): UseScreenSizeHook => {
  const [currentWidth, setCurrentWidth] = useState(0);
  const theme = useTheme();
  const { md, lg, xl, sm } = theme.breakpoints.values;
  useEffect(() => {
    setCurrentWidth(window.innerWidth);
    const setScreenWidth = () => setCurrentWidth(window.innerWidth);
    window.addEventListener('resize', setScreenWidth);

    return () => window.removeEventListener('resize', setScreenWidth);
  }, []);

  const isXs = useMemo(() => currentWidth < sm, [currentWidth]);

  const isSmall = useMemo(() => currentWidth < md, [currentWidth]);

  const isMedium = useMemo(() => currentWidth < lg && currentWidth >= md, [currentWidth]);

  const isLarge = useMemo(() => currentWidth < xl && currentWidth >= lg, [currentWidth]);

  const isXLarge = useMemo(() => currentWidth >= xl, [currentWidth]);

  const isBetween = useCallback(
    (start: number, finish: number) => currentWidth > start && currentWidth <= finish,
    [currentWidth],
  );

  return {
    ...theme.breakpoints.values,
    isXs,
    isSmall,
    isMedium,
    isLarge,
    isXLarge,
    isBetween,
    currentWidth,
  };
};
