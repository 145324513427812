const routes = require('../names');

const en = {
  [routes.acceptInvite]: {
    pathname: '/accept-invite',
  },
  [routes.accountActivate]: {
    pathname: '/account-activate',
  },
  [routes.accountActivateProviderLink]: {
    pathname: '/account-activate-provider-link',
  },
  [routes.forgotPassword]: {
    pathname: '/forgot-password',
  },
  [routes.login]: {
    pathname: '/login',
  },
  [routes.messageCenter]: {
    pathname: '/message-center',
  },
  [routes.profile]: {
    pathname: '/profile/[userId]/[[...action]]',
  },
  [routes.register]: {
    pathname: '/register',
  },
  [routes.restorePassword]: {
    pathname: '/restore-password',
  },
  [routes.settings]: {
    pathname: '/settings',
  },
  [routes.users]: {
    pathname: '/users',
  },
  [routes.userInvite]: {
    pathname: '/management/invite-user',
  },
  [routes.usersActive]: {
    pathname: '/users/active',
  },
  [routes.userEdit]: {
    pathname: '/users/edit/[id]',
  },
  [routes.files]: {
    pathname: '/files',
  },
  [routes.filesEdit]: {
    pathname: '/files/edit/[id]',
  },
  [routes.typography]: {
    pathname: '/typography',
  },
  [routes.home]: {
    pathname: '/',
  },
  [routes.imprint]: {
    pathname: '/imprint',
  },
  [routes.terms]: {
    pathname: '/terms',
  },
  [routes.privacy]: {
    pathname: '/privacy',
  },
  [routes.dataPreferences]: {
    pathname: '/data-preferences',
  },
  [routes.management]: {
    pathname: '/management',
  },
  [routes.adminstrationUsers]: {
    pathname: '/administration/users',
  },
  [routes.adminstrationCustomers]: {
    pathname: '/administration/customers',
  },
  [routes.companies]: {
    pathname: '/management/companies',
  },
  [routes.customersUsers]: {
    pathname: '/management/customers',
  },
  [routes.invitations]: {
    pathname: '/management/invitations',
  },
  [routes.helmUsers]: {
    pathname: '/management/helm-users',
  },
  [routes.userInvitesEdit]: {
    pathname: '/management/edit-invite/[inviteId]',
  },
  [routes.contracts]: {
    pathname: '/contracts',
  },
  [routes.contractDetails]: {
    pathname: '/contracts/[id]/[type]',
  },
  [routes.orderDetails]: {
    pathname: '/orders/[id]',
  },
  [routes.orderDeliveries]: {
    pathname: '/orders/[id]/deliveries',
  },
  [routes.dashboard]: {
    pathname: '/dashboard',
  },
  [routes.home]: {
    pathname: '/home',
  },
  [routes.overview]: {
    pathname: '/overview',
  },
  [routes.documents]: {
    pathname: '/documents',
  },
  [routes.inquiries]: {
    pathname: '/inquiries',
  },
  [routes.carbonFootprint]: {
    pathname: '/carbon-footprint',
  },
  [routes.compliance]: {
    pathname: '/compliance',
  },
  [routes.knowledgeCenter]: {
    pathname: '/knowledge-center',
  },
  [routes.helpCenter]: {
    pathname: '/help-center',
  },
  [routes.orders]: {
    pathname: '/orders',
  },
  [routes.orderDeliveryDetails]: {
    pathname: '/orders/[id]/deliveries/[deliveryId]',
  },
  [routes.deliveryDetails]: {
    pathname: '/deliveries/[deliveryId]',
  },
  [routes.marketIntelligence]: {
    pathname: '/market-intelligence',
  },
  [routes.marketIntelligenceCreate]: {
    pathname: '/market-intelligence/create',
  },
  [routes.marketIntelligenceEdit]: {
    pathname: '/market-intelligence/edit/[id]',
  },
  [routes.twoFactorAuthRegistration]: {
    pathname: '/2fa/register',
  },
  [routes.twoFactorAuthAuthenticate]: {
    pathname: '/2fa/authenticate',
  },
  [routes.marketIntelligenceProductDetails]: {
    pathname: '/market-intelligence/products/[id]',
  },
  [routes.deliveries]: {
    pathname: '/deliveries',
  },
  [routes.companyCustomEmails]: {
    pathname: '/management/companies/[id]/custom-emails',
  },
};
module.exports = en;
