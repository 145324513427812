import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationMessage } from 'modules/common/components/message-top-bar/interfaces';

interface MessageTopBarState {
  message: NotificationMessage | null;
}

const initialState: MessageTopBarState = {
  message: null,
};

export const messageTopBarSlice = createSlice({
  name: 'topbar',
  initialState,
  reducers: {
    setTopbarMessage: (state, action: PayloadAction<NotificationMessage>) => {
      state.message = action.payload;
    },
    clearTopbarMessage: (state) => {
      state.message = null;
    },
  },
});

export const { setTopbarMessage, clearTopbarMessage } = messageTopBarSlice.actions;
export default messageTopBarSlice.reducer;
