import { yup } from 'modules/common/validation';

export interface AppPrivateConfigInterface {
  backend: {
    apiKey: string;
  };
  platform: {
    tenantId: string;
    apiKey: string;
    serviceAccount: string;
  };
  google: {
    clientId: string;
    clientSecret: string;
  };
  linkedin: {
    clientId: string;
    clientSecret: string;
  };
  apple: {
    clientId: string;
    clientSecret: string;
  };
  microsoftEntra: {
    clientId: string;
    clientSecret: string;
    tenantId: string;
  },
  nextAuth: {
    secret: string;
    session: {
      maxAge?: number;
    };
    jwt: {
      secret?: string;
    };
  };
}

let serverConfig: AppPrivateConfigInterface;

if (typeof window === 'undefined') {
  // will be cutted by webpack on client side
  serverConfig = Object.freeze({
    backend: {
      apiKey: yup.string().trim().default('CHANGE_ME_3').validateSync(process.env.ROQ_ONE_API_KEY),
    },
    platform: {
      // TODO replace this hardcoded value with values from Secret Manager
      tenantId: yup.string().trim().default('38ebbfb7-c1ad-434f-9182-16bc0fbf3b25').validateSync(process.env.TENANT_ID),
      apiKey: yup.string().trim().default('35d40d5f-92cd-4bc5-87f8-aa95ba15308e').validateSync(process.env.API_KEY),
      serviceAccount: yup
        .string()
        .trim()
        .default('project-service-account@roq.tech')
        .validateSync(process.env.ROQ_PLATFORM_SERVICE_ACCOUNT_EMAIL),
    },
    google: {
      clientId: yup
        .string()
        .trim()
        .default('285701372632-tnfg6ts212bnqoeio1hrhosmrte00hqc.apps.googleusercontent.com')
        .validateSync(process.env.GOOGLE_CLIENT_ID),
      clientSecret: yup
        .string()
        .trim()
        .default('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')
        .validateSync(process.env.GOOGLE_CLIENT_SECRET),
    },
    linkedin: {
      clientId: yup.string().trim().default('77ur05vja2ehus').validateSync(process.env.LINKEDIN_CLIENT_ID),
      clientSecret: yup.string().trim().default('LjxYohan153uz9PR').validateSync(process.env.LINKEDIN_CLIENT_SECRET),
    },
    apple: {
      clientId: yup.string().trim().default('com.roq-one.development').validateSync(process.env.APPLE_CLIENT_ID),
      clientSecret: yup
        .string()
        .trim()
        .default(
          'eyJhbGciOiJFUzI1NiIsInR5cCI6Imp3dCIsImtpZCI6IkpNV1A1MjRBTTkifQ.eyJpc3MiOiI1OUpLQzM4TDU0IiwiYXVkIjoiaHR0cHM6Ly9hcHBsZWlkLmFwcGxlLmNvbSIsInN1YiI6ImNvbS5yb3Etb25lLmRldmVsb3BtZW50IiwiaWF0IjoxNjQ3MzcxNjU3LCJleHAiOjE2NjMxMzk2NTd9.4xme3wp3x_fJbImAJqJxyGmmLINDy9PVZTA7695hbdQ9AGmG-awZYSFyi8jxbJ_qpk4_ZW3QeuFp4PN8je0wIg',
        )
        .validateSync(process.env.APPLE_CLIENT_SECRET),
    },
    microsoftEntra: {
      clientId: yup.string().default('d27b2abd-9531-4d41-a79f-ed53e8d22e0a').validateSync(process.env.AZURE_AD_CLIENT_ID),
      clientSecret: yup.string().default('4w78Q~bOirzTPI-yCCvfxcRREaMkGOlicq~wGcpx').validateSync(process.env.AZURE_AD_CLIENT_SECRET),
      tenantId: yup.string().default('a4b97f47-0718-4fa5-8e95-ef32e4593ede').validateSync(process.env.AZURE_AD_TENANT_ID),
    },
    nextAuth: {
      secret: yup.string().trim().default('CHANGE_ME_4').validateSync(process.env.NEXTAUTH_SECRET),
      session: {
        maxAge: yup
          .number()
          .default(28800) // 8 hrs
          .validateSync(process.env.NEXTAUTH_SESSION_TTL),
      },
      jwt: {
        secret: yup.string().trim().default('CHANGE_ME_5').validateSync(process.env.NEXTAUTH_ENCRYPTION_KEY),
      },
    },
  });
}
export { serverConfig };
