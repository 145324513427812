import { ApolloClient, createHttpLink, from, InMemoryCache, Observable, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { publicConfig } from 'configuration/app';
import store from 'configuration/redux/store';
import { logoutAction, logoutOnExpiredAction, refreshAccessTokenAction } from 'modules/auth/auth.slice';
import { ErrorMessageTypeEnum } from 'modules/common/enums';
import { UserSessionInfoInterface } from 'modules/common/interfaces/user-session-info.interface';
import { getCookieJson } from 'modules/common/utils/cookie';
import { CurrentBusinessPartnerInternalCookieName } from 'modules/current-business-partner-selector/constants/current-business-partner-internal-cookie-name';
import Router from 'next/router';
import { getSession } from 'next-auth/react';
import { finalize, from as rxjsFrom, mergeMap } from 'rxjs';
import { uuid as uuidv4 } from 'uuidv4';

let clientSingleton;
let isValidating = false;

export function apolloClient(): ApolloClient<InMemoryCache> {
  if (!clientSingleton) {
    const httpLink = split(
      (operation) => operation.getContext().service === 'platform',
      createHttpLink({
        uri: `${publicConfig.platform.graphqlUri}`,
      }),
      createHttpLink({
        uri: `${publicConfig.backend.graphqlUri}`,
        credentials: 'include',
      }),
    );

    const authLink = setContext(async (_, { headers, service }) => {
      const { auth } = store.getState();
      const timezone = auth?.user?.timezone ?? publicConfig.timezone.default;
      const locale = auth?.user?.locale ?? Router.locale;
      const accessToken = auth?.accessToken ?? null;
      const currentBusinessPartnerInternal = getCookieJson(CurrentBusinessPartnerInternalCookieName);

      return {
        headers: {
          ...headers,
          locale,
          timezone,
          [publicConfig.backend.currentBusinessPartnerIdHeader]: currentBusinessPartnerInternal?.id,
          ...(auth.platformAccessToken && {
            [publicConfig.platform.authorizationHeader]: 'Bearer ' + auth.platformAccessToken,
          }),
          ...(service === 'platform'
            ? {
              [publicConfig.platform.requestIdHeader]: uuidv4(),
            }
            : {
              authorization: accessToken ? `Bearer ${accessToken}` : '',
            }),
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions?.exception?.status || err.extensions?.response?.statusCode) {
            case 401: {
              // next-auth should check if access token is expired and refresh it (see jwt callback)
              if ((err.message.match(ErrorMessageTypeEnum.INVALID_JWT_TOKEN))) {
                return new Observable((observer) => {
                  // isValidating is used to confine request maxLimit to one only, for a given stack of errors.
                  if (!isValidating) {
                    isValidating = true;
                    rxjsFrom(store.dispatch(logoutAction()))
                      .pipe(
                        // After logout completes, initiate the redirect
                        mergeMap(() => rxjsFrom(Router.push('/login'))),
                        // Ensure the observer completes after the redirect
                        finalize(() => {
                          isValidating = false
                          return observer.complete()
                        })
                      )
                      .subscribe({
                        error: (e) => {
                          isValidating = false
                          return observer.error(e)
                        }
                      });
                  }
                });
              } else {
                return new Observable((observer) => {
                  getSession()
                    .then((session: UserSessionInfoInterface['session']) => {
                      const accessToken = session?.accessToken as string;
                      const platformAccessToken = session?.platformAccessToken as string;
                      store.dispatch(refreshAccessTokenAction({ accessToken }));

                      operation.setContext(({ headers = {}, service }) => ({
                        headers: {
                          ...headers,
                          locale: Router.locale,
                          ...(service === 'platform'
                            ? { [publicConfig.platform.authorizationHeader]: platformAccessToken }
                            : { authorization: accessToken ? `Bearer ${accessToken}` : '' }),
                        },
                      }));
                    })
                    .then(() => {
                      const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                      };

                      // Retry last failed request
                      forward(operation).subscribe(subscriber);
                    })
                    .catch((error) => {
                      // No refresh or client token available, we force user to login
                      observer.error(error);
                      store.dispatch(logoutOnExpiredAction());
                      void Router.push('/login');
                    });
                });
              }
            }
          }
        }
      }
    });

    clientSingleton = new ApolloClient({
      link: from([authLink, errorLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only', // We don't need to use Apollo's cache as we have the Redux Store
        },
      },
    });
  }

  return clientSingleton;
}
