import { createSlice } from '@reduxjs/toolkit';
import { AddressOptionInterface } from 'modules/forms/components/address-search-multi/address-search-multi.component';
import { OrderTypeAutocompleteOption } from 'modules/forms/components/order-type-autocomplete/interfaces';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search';
import { OverviewFilterInterface } from 'views/overview/hooks/use-overview-filter.hook';

export interface OverviewFilterStateInterface {
  selectedProduct: ProductAutocompleteOptionInterface;
  selectedOrderType: OrderTypeAutocompleteOption;
  filter: OverviewFilterInterface;
  selectedShipToAddresses: AddressOptionInterface[];
}

const initialState: OverviewFilterStateInterface = {
  selectedProduct: null,
  selectedOrderType: null,
  selectedShipToAddresses: [],
  filter: {},
};

export const overviewFilterSlice = createSlice({
  name: 'overviewFilter',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setSelectedOrderType: (state, action) => {
      state.selectedOrderType = action.payload;
    },
    setSelectedShipToAddresses: (state, action) => {
      state.selectedShipToAddresses = action.payload;
    },
  },
});

export const { setFilter, setSelectedOrderType, setSelectedProduct, setSelectedShipToAddresses } =
  overviewFilterSlice.actions;

export default overviewFilterSlice.reducer;
