const routeNames = Object.freeze({
  home: 'home',
  acceptInvite: 'accept-invite',
  accountActivate: 'account-activate',
  accountActivateProviderLink: 'account-activate-provider-link',
  forgotPassword: 'forgot-password',
  login: 'login',
  messageCenter: 'message-center',
  profile: 'profile',
  register: 'register',
  restorePassword: 'restore-password',
  settings: 'settings',
  users: 'users',
  usersActive: 'users-active',
  customersUsers: 'customer-users',
  userInvite: 'users-invite',
  userEdit: 'user-edit',
  files: 'files',
  filesEdit: 'files-edit',
  userProfile: 'user-profile',
  userInvitesEdit: 'user-invites-edit',
  imprint: 'imprint',
  terms: 'terms',
  privacy: 'privacy',
  dataPreferences: 'data-preferences',
  customerUsers: 'customer-users',
  helmUsers: 'helm-users',
  invitations: 'invitations',
  management: 'management',
  adminstrationUsers: 'users',
  adminstrationCustomers: 'customers',
  companies: 'companies',
  contracts: 'contracts',
  contractDetails: 'contract-details',
  orderDetails: 'order-details',
  orderDeliveries: 'deliveries',
  dashboard: 'dashboard',
  home: 'home',
  overview: 'overview',
  inquiries: 'inquiries',
  documents: 'documents',
  carbonFootprint: 'carbon-footprint',
  compliance: 'compliance',
  knowledgeCenter: 'knowledge-center',
  helpCenter: 'help-center',
  orders: 'orders',
  deliveryDetails: 'delivery-details',
  orderDeliveryDetails: 'order-delivery-detail',
  marketIntelligence: 'market-intelligence',
  marketIntelligenceCreate: 'market-intelligence-create',
  marketIntelligenceEdit: 'market-intelligence-edit',
  twoFactorAuthRegistration: 'two-factor-auth-registration',
  twoFactorAuthAuthenticate: 'two-factor-auth-authenticate',
  marketIntelligenceProductDetails: 'market-intelligence-product-details',
  deliveries: 'deliveries',
  companyCustomEmails: 'company-custom-emails',
});

module.exports = routeNames;
