export enum UsersSortEnum {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  STATUS = 'STATUS',
  TIMEZONE = 'TIMEZONE',
  LOCALE = 'LOCALE',
  OPTED_IN = 'OPTED_IN',
  ROQ_IDENTIFIER = 'ROQ_IDENTIFIER',
  AVATAR_IMAGE_IDENTIFIER = 'AVATAR_IMAGE_IDENTIFIER',
}