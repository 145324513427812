import { ApolloError } from '@apollo/client';
import { createSlice } from '@reduxjs/toolkit';

export interface NotificationsErrorInterface extends ApolloError {}
export interface BusinessPartnerSidebarStateInterface {
  isOpen: boolean;
}

const initialState: BusinessPartnerSidebarStateInterface = {
  isOpen: false,
};

export const businessPartnerSidebarSlice = createSlice({
  name: 'businessPartnerSidebar',
  initialState,
  reducers: {
    toggleBusinessPartnerSidebarAction: (state) => {
      state.isOpen = !state.isOpen;
    },
    closeBusinessPartnerSidebarAction: (state) => {
      state.isOpen = false;
    },
    setBusinessPartnerSidebarAction: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export default businessPartnerSidebarSlice.reducer;
export const {
  toggleBusinessPartnerSidebarAction,
  closeBusinessPartnerSidebarAction,
  setBusinessPartnerSidebarAction,
} = businessPartnerSidebarSlice.actions;
