export enum ContractReleaseSortEnum {
  HELM_CONTRACT_ID = 'HELM_CONTRACT_ID',
  CUSTOMER_CONTRACT_REFERENCE = 'CUSTOMER_CONTRACT_REFERENCE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  PRODUCT = 'PRODUCT',
  COMPANY_NAME = 'COMPANY_NAME',
  KUNNR = 'KUNNR',
  productId = 'PRODUCT_ID',
}
