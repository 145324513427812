import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginCredentialsEnum } from 'modules/common/enums';
import { NextAuthError } from 'modules/common/errors';
import { signIn, SignInResponse } from 'next-auth/react';

export interface TwoFactorAuthenticationActionRequestDataInterface {
  email: string;
  password: string;
  code?: string;
}

export interface TwoFactorAuthenticationActionRequestInterface {
  data: TwoFactorAuthenticationActionRequestDataInterface;
}

export const twoFactorAuthenticationAction = createAsyncThunk(
  'auth/2faAuthentication',
  async (request: TwoFactorAuthenticationActionRequestInterface, thunkAPI): Promise<SignInResponse> => {
    try {
      const result: SignInResponse = await signIn(LoginCredentialsEnum.TwoFactorAuthenticate, { ...request.data, redirect: false })

      if (result?.error) {
        throw new NextAuthError(result?.error)
      }

      return result;
    } catch (err) {
      if (thunkAPI) {
        throw thunkAPI.rejectWithValue(err);
      }

      throw err
    }
  }
);
