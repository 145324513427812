module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
    defaultLanguage: 'en',
    fallbackLng: ['en'],
    defaultNS: 'common',
    dateFnsLocales:['en-US'],
  },
  localeMapping: {
    en: 'en-US',
  },
};
