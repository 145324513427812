/* eslint-disable @roq/correct-export-name-components-layouts-actions */
import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'modules/common/utils/request-gql';
import { UserInterface } from 'modules/users/interfaces';

export interface FetchUserByRoqIdActionVariablesInterface {
  id: string;
}

export interface FetchUserRequestInterface extends QueryOptions {
  variables: FetchUserByRoqIdActionVariablesInterface;
}

export interface FetchUserResponseInterface {
  user: UserInterface;
}

export const fetchUserByRoqIdAction = createAsyncThunk(
  'user/fetchOne',
  (request: FetchUserRequestInterface, thunkApi): Promise<FetchUserResponseInterface> =>
    requestGql<FetchUserResponseInterface>(request, thunkApi, 'userByRoqId'),
);
