import { DateFnsLocaleContext } from 'modules/date-time/contexts';
import { formatLocalizedDate, formatUtcDate } from 'modules/date-time/utils';
import { memo, useContext } from 'react';

type FormattedDateProps = {
  date: Date | string | number;
  format?: string;
  timezone?: string | undefined | null;
  twoLines?: boolean;
  localized?: boolean;
  utc?: boolean;
};

export const FormattedDate = memo(
  ({ date, timezone, format = 'dd.MM.yyyy', utc = true, twoLines = false, localized = false }: FormattedDateProps) => {
    const dateFnsLocale = useContext(DateFnsLocaleContext);

    if (localized) {
      return <>{formatLocalizedDate(new Date(date), 'de-DE', timezone)}</>;
    }

    if (utc) {
      if (twoLines) {
        const formattedDate = formatUtcDate(date, 'dd. MMM yyyy', timezone, dateFnsLocale);
        return (
          <>
            <span style={{
              fontSize: '15px',
              fontWeight: 400,
              lineHeight: '160%', 
              letterSpacing: '0.17px',
            }}>{formattedDate}</span>
          </>
        );
      }

      return <>{formatUtcDate(date, format, timezone, dateFnsLocale)}</>;
    }
  },
);

FormattedDate.displayName = 'FormattedDate';
