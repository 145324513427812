import { createSlice } from '@reduxjs/toolkit';
import { DocumentsStatusEnum } from 'modules/common/enums/documents-status.enum';
import { ContractTypeEnum } from 'modules/contract-details/enums';
import { DocumentTypeOptionInterface } from 'modules/documents/interfaces/document-type-option.interface';
import { AddressOptionInterface } from 'modules/forms/components/address-search-multi/address-search-multi.component';
import { AutocompleteCompanyOptionItemInterface } from 'modules/forms/components/autocomplete-company/autocomplete-company.component';
import { LastUpdatedByUserOptionInterface } from 'modules/forms/components/config-last-updated-by-user-search/last-updated-by-user-search.component';
import { IncotermsInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms.interface';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search/interfaces';
import { OptionInterface } from 'modules/forms/components/select/select.component';
import { OrderTypeEnum } from 'views/orders/enums';

export interface tableStateInterface {
  rowPerPage: number;
}

export interface FilterByStateInterface {
  isLoading: boolean;
  deliveryState?: {
    products: ProductAutocompleteOptionInterface[];
    shipToAddresses: AddressOptionInterface[];
    transportation: OptionInterface[];
    tableState: tableStateInterface;
    incoterms: IncotermsInterface[];
    statuses: OptionInterface[];
    orderType?: OptionInterface<OrderTypeEnum>[];
    searchById?: string;
  };
  orderState: {
    products: ProductAutocompleteOptionInterface[];
    orderTypes: OptionInterface[];
    statuses: OptionInterface[];
    shipToAddresses: AddressOptionInterface[];
    tableState: tableStateInterface;
    searchId: string;
  };
  companyConfigState: {
    products: ProductAutocompleteOptionInterface[];
    users: LastUpdatedByUserOptionInterface[];
    company: AutocompleteCompanyOptionItemInterface;
    statuses: OptionInterface[];
    tableState: tableStateInterface;
  };
  contractState: {
    years: OptionInterface[];
    products: ProductAutocompleteOptionInterface[];
    statuses: OptionInterface[];
    shipToAddresses: AddressOptionInterface[];
    tableState: tableStateInterface;
  };
  orderCustomerState: {
    fromOrderDate: Date;
    toOrderDate: Date;
    fromDeliveryDate: Date;
    toDeliveryDate: Date;
    orderId: string;
    customerOrderReference: string;
    productId: string;
    productName: string;
    contractSearchById: string;
    contractSearchByType: ContractTypeEnum;
  };

  companyTableState: {
    companies: AutocompleteCompanyOptionItemInterface[];
  };
  invoicesState?: {
    products: ProductAutocompleteOptionInterface[];
    shipToAddresses: AddressOptionInterface[];
    status: OptionInterface<DocumentsStatusEnum>[];
    tableState: tableStateInterface;
    orderId: string;
    deliveryId: string;
    incoterms: IncotermsInterface[];
    orderType: OptionInterface<OrderTypeEnum>[];
    documentTypes: DocumentTypeOptionInterface[];
    searchText: string;
  };
  otherDocumentsState?: {
    product: ProductAutocompleteOptionInterface;
    documentTypes: DocumentTypeOptionInterface[];
    tableState: tableStateInterface;
  };
}

const initialState: FilterByStateInterface = {
  isLoading: false,
  orderCustomerState: {
    orderId: null,
    productId: null,
    customerOrderReference: null,
    fromOrderDate: null,
    productName: null,
    toOrderDate: null,
    fromDeliveryDate: null,
    toDeliveryDate: null,
    contractSearchById: null,
    contractSearchByType: ContractTypeEnum.BOTH,
  },
  companyConfigState: {
    products: [],
    users: [],
    company: null,
    statuses: [],
    tableState: {
      rowPerPage: 10,
    },
  },
  orderState: {
    products: [],
    orderTypes: [],
    statuses: [],
    shipToAddresses: [],
    searchId: '',
    tableState: {
      rowPerPage: 20,
    },
  },
  deliveryState: {
    products: [],
    shipToAddresses: [],
    transportation: [],
    statuses: [],
    incoterms: [],
    tableState: {
      rowPerPage: 20,
    },
  },
  invoicesState: {
    products: [],
    shipToAddresses: [],
    status: [],
    incoterms: [],
    tableState: {
      rowPerPage: 20,
    },
    orderId: '',
    deliveryId: '',
    searchText: '',
    orderType: [],
    documentTypes: [],
  },
  otherDocumentsState: {
    product: null,
    documentTypes: [],
    tableState: {
      rowPerPage: 20,
    },
  },
  contractState: {
    years: [],
    products: [],
    statuses: [],
    shipToAddresses: [],
    tableState: {
      rowPerPage: 20,
    },
  },
  companyTableState: {
    companies: [],
  },
};

export const filterBySlice = createSlice({
  name: 'filterBy',
  initialState,
  reducers: {
    setOrderCustomerProductNameSearchByFilter: (state, { payload }) => {
      state.orderCustomerState.productName = payload.productName;
    },
    setCustomerOrderProductIdSearchByFilter: (state, { payload }) => {
      state.orderCustomerState.productId = payload.productId;
    },
    setCustomerOrderSearchByTypeFilter: (state, { payload }) => {
      state.orderCustomerState.contractSearchByType = payload;
    },
    resetCustomerOrderFilter: (state) => {
      state.orderCustomerState.fromOrderDate = null;
      state.orderCustomerState.toOrderDate = null;
      state.orderCustomerState.fromDeliveryDate = null;
      state.orderCustomerState.toDeliveryDate = null;
      state.orderCustomerState.contractSearchById = null;
      state.orderCustomerState.productId = null;
      state.orderCustomerState.productName = null;
      state.orderCustomerState.contractSearchByType = ContractTypeEnum.BOTH;
    },
    setCustomerOrderDateFilter: (state, { payload }) => {
      const { from, to } = payload;
      state.orderCustomerState.fromOrderDate = from;
      state.orderCustomerState.toOrderDate = to;
    },
    setCustomerOrderSearchByOrderId: (state, { payload }) => {
      const { orderId } = payload;
      state.orderCustomerState.orderId = orderId;
    },
    setCustomerOrderReferenceOfCustomer: (state, { payload }) => {
      const { customerOrderReference } = payload;
      state.orderCustomerState.customerOrderReference = customerOrderReference;
    },
    setCustomerContractSearchByIdFilter: (state, { payload }) => {
      state.orderCustomerState.contractSearchById = payload;
    },
    setCustomerDeliveryDateFilter: (state, { payload }) => {
      const { from, to } = payload;
      state.orderCustomerState.fromDeliveryDate = from;
      state.orderCustomerState.toDeliveryDate = to;
    },
    setCompanyTableCompanyFilter: (state, { payload }) => {
      state.companyTableState.companies = payload;
    },
    resetCompanyTableStateFilter: (state) => {
      state.companyTableState.companies = [];
    },
    //-------------------------------------Company Config Filter reducers--------------------------------------------
    setProductSearchByCompanyConfigFilter: (state, { payload }) => {
      state.companyConfigState.products = payload.product;
    },
    setCompanyForConfigFilter: (state, { payload }) => {
      state.companyConfigState.company = payload;
    },
    removeCompanyConfigProduct: (state, { payload }) => {
      state.companyConfigState.products = state.companyConfigState.products.filter(
        (product) => product.id !== payload.id,
      );
    },
    setUserSearchByCompanyConfigFilter: (state, { payload }) => {
      state.companyConfigState.users = payload.user;
    },
    removeCompanyConfigUser: (state, { payload }) => {
      state.companyConfigState.users = state.companyConfigState.users.filter((user) => user.id !== payload.id);
    },
    setConfigStatusFilter: (state, { payload }) => {
      state.companyConfigState.statuses = payload;
    },
    removeConfigStatus: (state, { payload }) => {
      state.companyConfigState.statuses = state.companyConfigState.statuses.filter(
        (type) => type.value !== payload.value,
      );
    },
    removeCompanyConfigCompanyAndRelatedProducts: (state) => {
      state.companyConfigState.company = null;
      state.companyConfigState.products = [];
    },
    resetCompanyConfigFilter: (state) => {
      state.companyConfigState.products = [];
      state.companyConfigState.users = [];
      state.companyConfigState.company = null;
      state.companyConfigState.statuses = [];
    },
    setCompanyConfigTableState: (state, { payload }) => {
      state.companyConfigState.tableState = payload;
    },
    //-------------------------------------Company Config Filter reducers--------------------------------------------

    //-------------------------------------Contract Filter reducers--------------------------------------------

    setContractYearFilter: (state, { payload }) => {
      state.contractState.years = payload;
    },
    removeYearFilter: (state, { payload }) => {
      state.contractState.years = state.contractState.years.filter((year) => year.value !== payload.value);
    },

    setContractStatusFilter: (state, { payload }) => {
      state.contractState.statuses = payload;
    },
    removeStatusFilter: (state, { payload }) => {
      state.contractState.statuses = state.contractState.statuses.filter((status) => status.value !== payload.value);
    },
    setProductSearchByFilter: (state, { payload }) => {
      state.contractState.products = payload.product;
    },
    removeProduct: (state, { payload }) => {
      state.contractState.products = state.contractState.products.filter((product) => product.id !== payload.id);
    },
    resetContractFilter: (state) => {
      state.contractState.years = [];
      state.contractState.products = [];
      state.contractState.statuses = [];
      state.contractState.shipToAddresses = [];
    },
    setContractTableState: (state, { payload }) => {
      state.contractState.tableState = payload;
    },
    setContractShipToAddressFilter: (state, { payload }) => {
      state.contractState.shipToAddresses = payload;
    },
    removeContractShipToAddress: (state, { payload }) => {
      state.contractState.shipToAddresses = state.contractState.shipToAddresses.filter(
        (type) => type.id !== payload.id,
      );
    },
    //-------------------------------------Contract Filter reducers--------------------------------------------

    //-------------------------------------Order Filter reducers--------------------------------------------
    setOrderProductSearchByFilter: (state, { payload }) => {
      state.orderState.products = payload.product;
    },
    removeOrderProduct: (state, { payload }) => {
      state.orderState.products = state.orderState.products.filter((product) => product.id !== payload.id);
    },
    setOrderTypesFilter: (state, { payload }) => {
      state.orderState.orderTypes = payload;
    },
    removeOrderTypes: (state, { payload }) => {
      state.orderState.orderTypes = state.orderState.orderTypes.filter((type) => type.value !== payload.value);
    },

    setOrderStatusFilter: (state, { payload }) => {
      state.orderState.statuses = payload;
    },
    setShipToAddressFilter: (state, { payload }) => {
      state.orderState.shipToAddresses = payload;
    },
    setOrderSearch: (state, { payload }) => {
      state.orderState.searchId = payload;
    },
    removeOrderStatus: (state, { payload }) => {
      state.orderState.statuses = state.orderState.statuses.filter((type) => type.value !== payload.value);
    },
    removeShipToAddress: (state, { payload }) => {
      state.orderState.shipToAddresses = state.orderState.shipToAddresses.filter((type) => type.id !== payload.id);
    },
    resetOrderFilter: (state) => {
      state.orderState.products = [];
      state.orderState.orderTypes = [];
      state.orderState.statuses = [];
      state.orderState.shipToAddresses = [];
      state.orderState.searchId = '';
    },
    setOrderTableState: (state, { payload }) => {
      state.orderState.tableState = payload;
    },

    //-------------------------------------Order Filter reducers--------------------------------------------
    //-------------------------------------Delivery Filter reducers--------------------------------------------
    setDeliveryProductSearchByFilter: (state, { payload }) => {
      state.deliveryState.products = payload.product;
    },
    removeDeliveryProduct: (state, { payload }) => {
      state.deliveryState.products = state.deliveryState.products.filter((product) => product.id !== payload.id);
    },
    setDeliveryShipToAddressFilter: (state, { payload }) => {
      state.deliveryState.shipToAddresses = payload;
    },
    removeDeliveryShipToAddress: (state, { payload }) => {
      state.deliveryState.shipToAddresses = state.deliveryState.shipToAddresses.filter(
        (type) => type.id !== payload.id,
      );
    },
    setDeliveryStatusesFilter: (state, { payload }) => {
      state.deliveryState.statuses = payload;
    },
    setTransportationFilter: (state, { payload }) => {
      state.deliveryState.transportation = payload;
    },
    removeTransportation: (state, { payload }) => {
      state.deliveryState.transportation = state.deliveryState.transportation.filter(
        (type) => type.value !== payload.value,
      );
    },
    removeDeliveryStatus: (state, { payload }) => {
      state.deliveryState.statuses = state.deliveryState.statuses.filter(
        (type) => type.value !== payload.value,
      );
    },
    resetDeliveryFilter: (state) => {
      state.deliveryState.products = [];
      state.deliveryState.shipToAddresses = [];
      state.deliveryState.transportation = [];
      state.deliveryState.incoterms = [];
      state.deliveryState.statuses = [];
      state.deliveryState.searchById = '';
      state.deliveryState.orderType = [];
    },
    setDeliveryTableState: (state, { payload }) => {
      state.deliveryState.tableState = payload;
    },
    setDeliveryIncotermsFilter: (state, { payload }) => {
      state.deliveryState.incoterms = payload;
    },
    removeDeliveryIncoterms: (state, { payload }) => {
      state.deliveryState.incoterms = state.deliveryState.incoterms.filter((type) => type.name !== payload.name);
    },
    removeDeliveryOrderType: (state, { payload }) => {
      if (payload.value !== OrderTypeEnum.SPOT) {
        state.deliveryState.searchById = ''
      }
      state.deliveryState.orderType = state.deliveryState.orderType.filter(type => type.value !== payload.value)
    },
    setDeliveryOrdertype: (state, { payload }) => {
      state.deliveryState.orderType = payload
    },
    setDeliverySearchById: (state, { payload }) => {
      state.deliveryState.searchById = payload
    },
    //-------------------------------------Delivery Filter reducers--------------------------------------------
    //-------------------------------------Invoices Filter reducers--------------------------------------------
    setInvoicesProductSearchByFilter: (state, { payload }) => {
      state.invoicesState.products = payload.product;
    },
    setInvoicesSearchByTextFilter: (state, { payload }) => {
      state.invoicesState.searchText = payload;
    },
    removeInvoicesProduct: (state, { payload }) => {
      state.invoicesState.products = state.invoicesState.products.filter((product) => product.id !== payload.id);
    },
    setInvoicesShipToAddressFilter: (state, { payload }) => {
      state.invoicesState.shipToAddresses = payload;
    },
    removeInvoicesShipToAddress: (state, { payload }) => {
      state.invoicesState.shipToAddresses = state.invoicesState.shipToAddresses.filter(
        (type) => type.id !== payload.id,
      );
    },
    setInvoicesStatusFilter: (state, { payload }) => {
      state.invoicesState.status = payload;
    },
    removeInvoicesStatus: (state, { payload }) => {
      state.invoicesState.status = state.invoicesState.status.filter((type) => type.value !== payload.value);
    },
    setInvoicesFilter: (state, { payload }) => {
      state.invoicesState = { ...state.invoicesState, ...payload };
    },
    removeInvoicesOrderType: (state, { payload }) => {
      state.invoicesState.orderType = state.invoicesState.orderType.filter((type) => type.value !== payload.value);
    },
    removeInvoicesDeliveryId: (state) => {
      state.invoicesState.deliveryId = null;
    },
    setInvoicesDocumentTypes: (state, { payload }) => {
      state.invoicesState.documentTypes = payload;
    },
    removeInvoicesDocumentTypes: (state, { payload }) => {
      state.invoicesState.documentTypes = state.invoicesState.documentTypes.filter((type) => type.name !== payload.name);
    },
    setInvoicesDeliveryId: (state, { payload }) => {
      state.invoicesState.deliveryId = payload;
    },
    setInvoicesOrderType: (state, { payload }) => {
      state.invoicesState.orderType = payload;
    },
    removeInvoicesOrderId: (state) => {
      state.invoicesState.orderId = null;
    },
    setInvoicesOrderId: (state, { payload }) => {
      state.invoicesState.orderId = payload;
    },
    resetInvoicesFilter: (state) => {
      state.invoicesState.products = [];
      state.invoicesState.shipToAddresses = [];
      state.invoicesState.status = [];
      state.invoicesState.incoterms = [];
      state.invoicesState.orderType = [];
      state.invoicesState.deliveryId = null;
      state.invoicesState.orderId = null;
      state.invoicesState.orderType = null;
      state.invoicesState.documentTypes = [];
      state.invoicesState.searchText = '';
    },
    setInvoicesIncoterms: (state, { payload }) => {
      state.invoicesState.incoterms = payload;
    },
    setInvoicesState: (state, { payload }) => {
      state.invoicesState = { ...state.invoicesState, ...payload };
    },
    setInvoicesTableState: (state, { payload }) => {
      state.invoicesState.tableState = payload;
    },
    //-------------------------------------Invoices Filter reducers--------------------------------------------
    //-------------------------------------OtherDocuments Filter reducers--------------------------------------------
    setOtherDocumentsProductSearchByFilter: (state, { payload }) => {
      state.otherDocumentsState.product = payload.product;
    },
    setOtherDocumentsState: (state, { payload }) => {
      state.otherDocumentsState = { ...state.otherDocumentsState, ...payload };
    },
    removeOtherDocumentsProduct: (state,) => {
      state.otherDocumentsState.product = null;
    },
    setOtherDocumentsDocumentTypesFilter: (state, { payload }) => {
      state.otherDocumentsState.documentTypes = payload;
    },
    removeOtherDocumentsDocumentTypes: (state, { payload }) => {
      state.otherDocumentsState.documentTypes = state.otherDocumentsState.documentTypes.filter(
        (type) => type.name !== payload.name,
      );
    },
    resetOtherDocuments: (state) => {
      state.otherDocumentsState.product = null;
      state.otherDocumentsState.documentTypes = [];
    },
    setOtherDocumentsTableState: (state, { payload }) => {
      state.otherDocumentsState.tableState = payload;
    },
    //-------------------------------------OtherDocuments Filter reducers--------------------------------------------
  },
});

export default filterBySlice.reducer;

export const {
  //--------Order Slice reducers------------
  setOrderProductSearchByFilter,
  removeOrderProduct,
  setOrderTypesFilter,
  removeOrderTypes,
  setOrderStatusFilter,
  setShipToAddressFilter,
  removeShipToAddress,
  removeOrderStatus,
  resetOrderFilter,
  setOrderTableState,
  setOrderSearch,
  //--------Order Slice reducers------------
  //--------Delivery Slice reducers------------
  setDeliveryProductSearchByFilter,
  removeDeliveryProduct,
  setDeliveryShipToAddressFilter,
  removeDeliveryShipToAddress,
  resetDeliveryFilter,
  setTransportationFilter,
  removeTransportation,
  setDeliveryTableState,
  removeDeliveryStatus,
  setDeliveryStatusesFilter,
  //--------Delivery Slice reducers------------
  //--------Companu Config Slice reducers------------
  setProductSearchByCompanyConfigFilter,
  removeCompanyConfigProduct,
  removeCompanyConfigCompanyAndRelatedProducts,
  setUserSearchByCompanyConfigFilter,
  removeCompanyConfigUser,
  resetCompanyConfigFilter,
  setCompanyForConfigFilter,
  setConfigStatusFilter,
  removeConfigStatus,
  setCompanyConfigTableState,
  //--------Companu Config Slice reducers------------
  setCustomerOrderSearchByOrderId,
  setCustomerOrderDateFilter,
  setCustomerDeliveryDateFilter,
  resetCustomerOrderFilter,
  setContractYearFilter,
  resetContractFilter,
  setCompanyTableCompanyFilter,
  setProductSearchByFilter,
  resetCompanyTableStateFilter,
  setCustomerOrderReferenceOfCustomer,
  removeProduct,
  setCustomerOrderProductIdSearchByFilter,
  setCustomerContractSearchByIdFilter,
  setOrderCustomerProductNameSearchByFilter,
  setCustomerOrderSearchByTypeFilter,
  setContractStatusFilter,
  setContractTableState,
  setContractShipToAddressFilter,
  removeContractShipToAddress,
  removeStatusFilter,
  removeYearFilter,
  setInvoicesProductSearchByFilter,
  setInvoicesSearchByTextFilter,
  setInvoicesShipToAddressFilter,
  setInvoicesStatusFilter,
  setInvoicesTableState,
  resetInvoicesFilter,
  removeInvoicesProduct,
  removeInvoicesShipToAddress,
  removeInvoicesStatus,
  removeDeliveryIncoterms,
  setDeliveryIncotermsFilter,
  removeOtherDocumentsProduct,
  removeOtherDocumentsDocumentTypes,
  resetOtherDocuments,
  setOtherDocumentsDocumentTypesFilter,
  setOtherDocumentsProductSearchByFilter,
  setOtherDocumentsTableState,
  setOtherDocumentsState,
  setInvoicesState,
  removeInvoicesDeliveryId,
  removeInvoicesOrderId,
  setInvoicesDeliveryId,
  setInvoicesOrderId,
  setInvoicesFilter,
  setInvoicesIncoterms,
  setInvoicesOrderType,
  removeInvoicesDocumentTypes,
  setInvoicesDocumentTypes,
  removeInvoicesOrderType,
  removeDeliveryOrderType,
  setDeliveryOrdertype,
  setDeliverySearchById
} = filterBySlice.actions;
