import { configureStore } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import reducer from 'configuration/redux/reducer';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { AuthStateInterface } from 'modules/auth';
import { FilterByStateInterface } from 'modules/filter-by';
import { OrderTypeListStateInterface } from 'modules/forms/components/order-type-autocomplete/slices/order-type-list.slice';
import { LayoutStateInterface } from 'modules/layout/layout.slice';
import { MessageCenterStateInterface } from 'modules/message-center';
import { NotificationPreferencesStateInterface } from 'modules/notification-preferences/notification-preferences.slice';
import { NotificationsStateInterface } from 'modules/notifications/notifications.slice';
import { ThemeStateInterface } from 'modules/theme/theme.slice';
import { UserFilesStateInterface } from 'modules/user-files/user-files.slice';
import { UserInvitesStateInterface } from 'modules/user-invites/user-invites.slice';
import { UsersStateInterface } from 'modules/users/users.slice';
import { OverviewFilterStateInterface } from 'views/overview/overview-filter.slice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['overviewFilter'],
};

export interface StoreInterface {
  auth: AuthStateInterface;
  notifications: NotificationsStateInterface;
  notificationPreferences: NotificationPreferencesStateInterface;
  messageCenter: MessageCenterStateInterface;
  theme: ThemeStateInterface;
  layout: LayoutStateInterface;
  userFiles: UserFilesStateInterface;
  users: UsersStateInterface;
  userInvites: UserInvitesStateInterface;
  filterBy: FilterByStateInterface;
  orderTypeList: OrderTypeListStateInterface;
  overviewFilter: OverviewFilterStateInterface;
}

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const storePersistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

// eslint-disable-next-line @roq/exports-should-follow-conventions
export default store;
