import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { OrderEnum } from 'modules/common/enums';
import { PaginationInterface } from 'modules/common/interfaces';
import { requestGql } from 'modules/common/utils/request-gql';
import { OrderInterface } from 'modules/order-details/interfaces';
import { OrderSortEnum, OrderStatusEnum } from 'modules/orders/enums';
import { OrderTypeEnum } from 'views/orders/enums';

export interface TablePaginationInterface extends PaginationInterface {
  order?: {
    order: OrderEnum;
    sort: OrderSortEnum;
  };
  filter?: {
    kunnr?: string;
    productIds?: string[];
    universalIds?: string[];
    orderTypes?: OrderTypeEnum[];
    status?: OrderStatusEnum[];
    firstProductId?: string;
    shipToAddressIds?: string[];
  };
}

export interface FetchOrdersResponseInterface {
  data: OrderInterface[];
  totalCount: number;
}

export interface UseFetchOrderHookInterface {
  fetchOrders: (query: TablePaginationInterface) => Promise<FetchOrdersResponseInterface>;
}

export const fetchOrders = (query: TablePaginationInterface): Promise<FetchOrdersResponseInterface> =>
  requestGql(
    {
      query: gql`
        query Orders($limit: Int, $offset: Int, $order: SpotContractOrderArgType, $filter: OrderFilterArgType) {
          orders(limit: $limit, order: $order, offset: $offset, filter: $filter) {
            data {
              id
              companyName
              orderType
              contractId
              customerOrderReference
              firstProductName
              firstProductId
              orderShippingAddress
              orderDeliveryDate
              orderCreatedAt
              deliveryCount
              status
              orderQuantity
              unitOfMeasure
              createdByEmailAddress
              orderFeedbackInternal {
                data {
                  userId
                  vbeln
                  rating
                }
              }
              products {
                name
                quantity
              }
            }
            totalCount
          }
        }
      `,
      variables: !isEmpty(query)
        ? {
          limit: query.limit,
          offset: query.offset,
          order: query.order,
          filter: query.filter,
        }
        : {},
    },
    null,
    'orders',
  );

export const useFetchOrders = (): UseFetchOrderHookInterface => ({
  fetchOrders,
});
