export const helmRed = {
  '50': '#f9ebea',
  '100': '#f3d8d5',
  '200': '#edc5c0',
  '300': '#e7b1ab',
  '400': '#e19e96',
  '500': '#dc8a80',
  '600': '#d6776b',
  '700': '#d06356',
  '800': '#ca5041',
  '900': '#c43c2c',
};
