export const helmLightBlue = {
  '50': '#ebf4fb',
  '100': '#d7e9f8',
  '200': '#c3def4',
  '300': '#afd3f1',
  '400': '#9cc7ed',
  '500': '#88bce9',
  '600': '#74b1e6',
  '700': '#60a6e2',
  '800': '#4c9bdfi',
  '900': '#3890db',
};
