import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { OrderEnum } from 'modules/common/enums';
import { PaginationInterface } from 'modules/common/interfaces';
import { requestGql } from 'modules/common/utils/request-gql';
import { ContractTypeEnum } from 'modules/contract-details/enums/contract-type.enum';
import { ContractInterface } from 'modules/contract-release/interfaces';
import { ContractReleaseSortEnum } from 'views/contract-release/enums';

export interface TablePaginationInterface extends PaginationInterface {
  order?: {
    order: OrderEnum;
    sort: ContractReleaseSortEnum;
  };
  filter?: {
    dateRange?: {
      startDate: Date;
      endDate: Date;
    };
    kunnrs?: string[];
    type?: ContractTypeEnum;
    productIds?: string[];
  };
}
export interface UseFetchContractReleaseHookInterface {
  fetchContracts: (query: TablePaginationInterface) => Promise<{
    data: ContractInterface[];
    totalCount: number;
  }>;
}

export interface FetchContractsInterface {
  data: ContractInterface[];
  totalCount: number;
}

export const fetchContracts = (query: TablePaginationInterface): Promise<FetchContractsInterface> =>
  requestGql(
    {
      query: gql`
        query contracts($limit: Int, $offset: Int, $order: ContractOrderArgType, $filter: ContractFilterArgType) {
          contracts(limit: $limit, order: $order, offset: $offset, filter: $filter) {
            data {
              product
              shippingAddress
              addressesConcat
              quantity
              orderCount
              deliveryCount
              productId
              startDate
              endDate
              months
              helmContractId
              type
              customerContractReference
              companyName
              kunnr
              spstg
              businessPartnerInternal {
                id
                name
              }
              createdByEmailAddress
              quantityUnit
            }
            totalCount
          }
        }
      `,
      variables: !isEmpty(query)
        ? {
          limit: query.limit,
          offset: query.offset,
          order: query.order,
          filter: query.filter,
        }
        : {},
    },
    null,
    'contracts',
  );

export const useFetchContractRelease = (): UseFetchContractReleaseHookInterface => ({
  fetchContracts,
});
